import { render, staticRenderFns } from "./TheAssessmentData.vue?vue&type=template&id=330ef738&scoped=true&"
import script from "./TheAssessmentData.vue?vue&type=script&lang=js&"
export * from "./TheAssessmentData.vue?vue&type=script&lang=js&"
import style0 from "./TheAssessmentData.vue?vue&type=style&index=0&id=330ef738&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "330ef738",
  null
  
)

export default component.exports