<template>
  <div name="reports" class="page">


  <div id="printable">

     <section class="bg-white rounded w-95p mx-auto px-6 pb-6 pt-4 mt-4 mb-8" >

      <div class="grid grid-cols-2 gap-4">
       <h4 class="grid text-lg font-semibold mb-8">Assessment Grades</h4>
       <ul class="inline-block text-right">
        <li class="inline-block" v-for="item in gradeList" :key="item.key">
           <button  type="button" @click="filterGradeValue(item)"
                         class="hover:text-white  font-bold py-2 px-5 mx-1 rounded"
                          v-bind:class="setGradeBackColor(item)"> 
                            <i class="far" v-bind:class="inGradeFilter(item) ? 'fa-check-square': 'fa-square'" ></i> {{ item.value }}
                          </button>
        </li>
       </ul>
       </div>
      


                   <table v-if="componentLoaded" class="table-wrapper w-full">
                              <thead class="bg-gray-100 rounded">
                                <tr>
                                  <th class="w-4/12 text-left p-2">Name</th>
                                   <th class="w-2/12 text-center pl-2">Performance</th>
                                    <th class="w-2/12 text-center pl-2">Personal / Social</th>
                                     <th class="w-2/12 text-center pl-2">Competition</th>
                                  <th class="w-2/12 text-center pl-2">Grade</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="course" v-for="item in filteredAssessments"> 
                                   <td > 
                                    <div class="upper">
                                       {{ item.name }}
                                   
                                    </div>
                                  </td>
 
                                <td class="text-center font-bold"  :class="setGradeColor(item.criteriaA)"> {{ item.criteriaA }}</td>
                                <td class="text-center font-bold"   :class="setGradeColor(item.criteriaB)"> {{ item.criteriaB}}</td>
                                <td class="text-center font-bold"  :class="setGradeColor(item.criteriaC)"> {{ item.criteriaC }}</td>
                                <td class="text-center font-bold"  :class="setGradeColor(item.grade)"> {{ item.grade }}</td>
                                </tr>
                                  
                               
                              </tbody>
                              <tfoot>

                              </tfoot>
                            </table>
                            
                             <p v-else>We could not find any data for your given selection.</p>

        </section>
      

        </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SchoolService } from "@/services/school.service";
import {
  Grades
} from "@/models/settings";

export default {
  name: "reports",
  props: ["assessments"],
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess"
    }),
    componentLoaded() {
      return this.assessments.length > 0;
    },
    gradeList() {
      return this.grades
    },
    filteredAssessments() {
      let vm = this
       if (vm.filteredGrades.length == 0 ) {
        return vm.assessments
      } else {
        let lows = vm.filteredGrades.map(s => s.low)
        let highs = vm.filteredGrades.map(s => s.high)
        let all = lows.concat(highs).sort((a, b) => a - b)

         var filtered = vm.assessments.filter(function(aa) {
          return (aa.grade >= all[0] && aa.grade <= all[all.length - 1])
         });

        return filtered;
    }
  }
  },
  data() {
    return {
      grades: [],
      filteredGrades: [],
    };
  },
  created() {
    this.fetchSettings()
  },
  mounted() {},
  methods: {
    fetchSettings() {
      SchoolService.getAll("settings")
        .then(response => this.buildSettingsList(response.data))
        .catch(err => {
          console.log("Settings fetch err " + err);
          this.errored = true;
        })
    },
    buildSettingsList(data) {
      this.parseGrades(data);
    },
    parseGrades(data) {
      var grades = data.filter(function(item) {
        return item.key === Grades.KEY_STRING;
      });
      let gradeValues = grades.length > 0 ? JSON.parse(grades[0].value) : undefined;

      this.grades = Grades.from(gradeValues);
    },
    setGradeBackColor(grade) {
      return this.inGradeFilter(grade) 
      ? grade.background + ' text-white'
      : 'bg-gray-100 text-gray-500 hover:' + grade.background
    },
    filterGradeValue(grade) {
      if (this.containsGrade(grade)) {
        this.filteredGrades.splice(this.filteredGrades.findIndex(item => item.key === grade.key), 1)
      } else {
        this.filteredGrades.push(grade);
      }
    },
    containsGrade(grade) {
      return this.filteredGrades.some(p => p.key === grade.key)
    },
    inGradeFilter(grade) {
      return this.containsGrade(grade)
    },
    setGradeColor(score) {
      let x = score;
      switch (true) {
        case x >= 1 && x < 1.67:
          return "bg-orange-300";
          break;
        case x >= 1.67 && x < 2.34:
          return "bg-gray-300";
          break;
        case x >= 2.34 && x < 3.1:
          return "bg-yellow-300";
          break;
        case x >= 3.1:
          return "bg-teal-300";
          break;
        case x > 0 && x < 1:
          return "background-color: #A0DBFA;";
          break;
        default:
          return "";
      }        
    },
    printReport() {
      window.print();
    }
  },
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.upper {
  text-transform: uppercase;
}

.smaller {
  font-size: 75% !important;
}

select {
  font-weight: bolder;
  font-size: 13pt;
  display: inline !important;
}

td {
  border-left: 1px solid rgba(230, 236, 245, 0.75) !important;
}
</style>
